<template>
  <span class="app_den_tp_det_ck" @click="setToMake()">
    <!-- <h1 class="animated infinite bounce delay-2s">Example</h1> -->
    <i v-show="maked" title="Realizado" class="fa fa-check-circle text-success"></i>
    <span v-show="!maked && !toMake" class="animated fadeIn">
      <i title="Aun no realizado" class="fa fa-check-circle text-muted"></i>
    </span>
    <span v-show="toMake" class="animated fadeIn">
      <i  title="Marcado para realizar" class="fa fa-check-circle text-primary"></i>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    maked: {},
    toMake: {
      default: false
    }
  },
  methods: {
    setToMake() {
      if(!this.maked) {
        this.$emit('update:toMake', this.toMake ? false : true);
      }
    }
  }
}
</script>

<style>
 .app_den_tp_det_ck {
   font-size: 1.5em;
   cursor: pointer;
 }
</style>
