<template>
  <form @submit.prevent="save()">
    <h4>PRESTACION ADICIONAL</h4>
    <app-alert
      class="alert-info"
    >Estas prestaciones adicionales no se consideran en el presupuesto, pero si se consideran como deuda y en la cantidad pagada</app-alert>
    <div class="form-group">
      <label for>Producto</label>
      <SelectProduct v-model="product_id"></SelectProduct>
    </div>
    <div class="form-group">
      <label for>Monto</label>
      <app-input-money :selCurrDis="true" :quantity.sync="unit_price" :moneyCode="money_code"></app-input-money>
    </div>
    <div class="form-group">
      <label for>Descripcion</label>
      <app-textarea v-model="comment"></app-textarea>
    </div>
    <app-button-submit></app-button-submit>
  </form>
</template>

<script>
import { DentalClinicService } from "../service";
import SelectProduct from "../../store-module/products/Select";

export default {
  components: {
    SelectProduct
  },
  props: {
    money_code: {},
    treatment_plan_id: {}
  },
  data: () => ({
    unit_price: undefined,
    comment: undefined,
    id: undefined, // treatment_plan_detail_id
    product_id: undefined
  }),
  methods: {
    save() {
      DentalClinicService.saveTreatmentPlanDetail({
        treatment_plan_id: this.treatment_plan_id,
        ...this.$data
      }).then(() => {
        this.$emit("submitted");
      });
    }
  }
};
</script>

<style>
</style>
