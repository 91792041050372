<template>
  <div>
    <h4>FINANCIAMIENTOS</h4>
    <table class="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>FECHA INICIO</th>
          <th>MONTO</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="l in financings" :key="l.id">
          <td>{{ l.id }}</td>
          <td>{{ l.begin_date }}</td>
          <td>
            <app-span-money
              :quantity="l.amount"
              :moneyCode="l.money_code"
            ></app-span-money>
          </td>
          <td>
            <router-link
              :to="'/dental-clinic/financings/' + l.id"
              class="btn btn-sm"
            >
              <i class="fa fa-link"></i>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- <button class="btn btn-primary mt-1" @click="$refs.dFormCreate.show(); $refs.form.reset()">Nueva financiacion</button>
    <app-modal-basic ref="dFormCreate">
      <FormCreate 
        @submitted="$refs.dFormCreate.hide(); load();" 
        ref="form" 
        :plan="plan" 
        :details="details"
        :treatment_plan_id="treatment_plan_id"></FormCreate>
    </app-modal-basic>-->
  </div>
</template>

<script>
import { DentalClinicService } from "../service";
// import FormCreate from "./FormCreateFromtreatment";
export default {
  components: {
    // FormCreate
  },
  props: {
    // details: {
    //   type: Array,
    //   default: () => ([])
    // },
    treatment_plan_id: {},
    plan: {}
  },
  data: () => ({
    financings: []
  }),
  watch: {
    treatment_plan_id() {
      this.getFinancing();
    }
  },
  methods: {
    load() {
      this.getFinancing();
      // this.loadForm();
    },
    // loadForm() {
    //   this.$refs.form.load();
    // },
    getFinancing() {
      DentalClinicService.getFinancings({
        treatment_plan_id: this.treatment_plan_id
      }).then(res => {
        this.financings = res.data;
      });
    }
  }
};
</script>

<style></style>
