<template>
  <div>
    <div class="form-group">
      <label for="">Doctor</label>
      <SelectEmployee ref="selEmp" v-model="employee_id"></SelectEmployee>
    </div>
    <div class="form-group">
      <label for="">Producto</label>
      <SelectProduct v-model="product_id" ref="selPro"></SelectProduct>
      <!-- <input type="text" class="form-control" disabled :value="product_name" /> -->
    </div>
    <div class="form-group">
      <label for="">Laboratorio</label>
      <SelectSupplierByProduct
        v-model="supplier_id"
        :product_id="product_id"
      ></SelectSupplierByProduct>
    </div>
    <div class="form-group">
      <label for="">Comentario</label>
      <app-textarea v-model="comment"></app-textarea>
    </div>
    <app-button-submit @click="save()"></app-button-submit>
  </div>
</template>
<script>
import SelectEmployee from "../../admin-module/employees/Select";
import SelectSupplierByProduct from "../../logistic-module/supplier-products/SelectSupplierByProduct.vue";
import { DentalClinicService } from "../service";
import { SelectProduct } from "../../store-module";
import { mapState } from "vuex";

export default {
  components: {
    SelectEmployee,
    SelectSupplierByProduct,
    SelectProduct
  },

  // directives
  // filters

  props: {
    default_employee_id: {}
  },

  data: () => ({
    employee_id: null,
    product_id: null,
    supplier_id: null,
    product_name: null,
    comment: null,
    treatment_plan_detail_id: null
  }),

  computed: {
    ...mapState({
      categorie_id: (s) => s.config.dental.laboratory.categorie_id
    })
  },

  watch: {
    default_employee_id(to) {
      this.$refs.selEmp.setValueFromId(to);
    }
  },

  mounted() {
    //
  },

  methods: {
    load({ product_name, product_id, id }) {
      this.product_name = product_name;
      this.product_id = product_id;
      this.comments = null;
      this.supplier_id = null;
      this.treatment_plan_detail_id = id;
      this.$refs.selPro.setValueFromId(product_id);
    },
    save() {
      DentalClinicService.reqTreatmentDetaillabJob(this.$data).then(() => {
        this.$emit("submitted");
      });
    }
  }
};
</script>

<style></style>
