<template>
  <form @submit.prevent="save()">
    <h4>Solicitar Trabajo de Laboratorio</h4>
    <div class="form-group">
      <label for>Doctor</label>
      <SelectEmployee ref="selEmp" v-model="employee_id"></SelectEmployee>
    </div>
    <div class="form-group">
      <label for>Comentario</label>
      <app-textarea v-model="comment"></app-textarea>
    </div>
    <app-button-submit text="Solicitar"></app-button-submit>
  </form>
</template>

<script>
import { DentalClinicService } from "../service";
import SelectEmployee from "../../admin-module/employees/Select";

export default {
  components: { SelectEmployee },
  data: () => ({
    employee_id: undefined,
    comment: "",
    lab_job_id: undefined
  }),
  methods: {
    loadLabJob(employee_id, lab_job_id) {
      this.$refs.selEmp.setValueFromId(employee_id);
      this.lab_job_id = lab_job_id;
    },
    save() {
      DentalClinicService.requestLaboratoryJob(this.$data).then(() => {
        this.$emit("submitted");
      });
    }
  }
};
</script>

<style></style>
