<template>
  <div>
    <h4>ADICIONALES</h4>
    <template v-for="(l, i) in products">
      <div class="form-group" :key="i">
        <label for=""> {{ l }} </label>
        <app-input-money
          :quantity.sync="productPays[i]"
          :moneyCode="1"
        ></app-input-money>
      </div>
    </template>
    <h4>LISTA PARA PAGAR</h4>
    <table class="table">
      <tbody>
        <tr v-for="l in toPay" :key="l.id">
          <!-- <td>
            <app-checkbox :id="'cb' + l.id" v-model="l.toPay" placeholder></app-checkbox>
          </td>-->
          <td>
            <app-checkbox
              :id="'cb' + l.id"
              v-model="l.toPay"
              @click="calcularTotal()"
              >{{ l.product_name }}</app-checkbox
            >
          </td>
          <td :class="{ 'text-success': l.maked }">
            {{ l.maked ? "Realizado" : "Pendiente" }}

            <br />
            <ToothPiece
              v-for="l1 in l.th_list"
              :key="l1"
              :piece="l1"
            ></ToothPiece>
          </td>
          <td class="text-right">
            <app-span-money
              :quantity="l.unit_price - l.amount_paid"
              :moneyCode="plan.money_code"
            ></app-span-money>
          </td>
        </tr>
        <tr>
          <!-- <th></th> -->
          <th>TOTAL</th>
          <th></th>
          <th class="text-right">
            <app-span-money
              :quantity="total"
              :moneyCode="plan.money_code"
            ></app-span-money>
          </th>
        </tr>
      </tbody>
    </table>
    <!-- <div class="form-group">
      <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline1"
          v-model="payNow"
          name="methodToPay"
          :value="2"
          class="custom-control-input"
        >
        <label class="custom-control-label" for="customRadioInline1">Asignar Abonos</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline2"
          v-model="payNow"
          name="methodToPay"
          :value="1"
          class="custom-control-input"
        >
        <label class="custom-control-label" for="customRadioInline2">Pago</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline3"
          v-model="payNow"
          name="methodToPay"
          :value="0"
          class="custom-control-input"
        >
        <label class="custom-control-label" for="customRadioInline3">A cuenta</label>
      </div>
    </div> -->

    <div class="form-group" v-show="!payNow">
      <label for>Dinero disponible:</label>
      <!-- <app-span-money :quantity="client_money" :moneyCode="plan.money_code"></app-span-money> -->
      <app-input-money
        :quantity="client_money"
        :moneyCode="plan.money_code"
        :disabled="true"
      ></app-input-money>
    </div>
    <div v-show="payNow">
      <div class="form-group">
        <label for>Metodo de Pago</label>
        <app-select-payment-method
          v-model="reg.payment_method_id"
        ></app-select-payment-method>
      </div>
      <!-- <div class="form-group">
        <label for="">VISA</label>
        <app-input-money
          quantity="0"
          :moneyCode="plan.money_code"
        ></app-input-money>
      </div> -->

      <div class="form-group">
        <label for>Monto Entregado</label>
        <app-input-money
          :quantity.sync="reg.pay"
          :moneyCode="plan.money_code"
          :byKeyPress="false"
          :selCurrDis="true"
          @update:quantity="reg.pay_change = pf($event - total)"
        ></app-input-money>
      </div>
      <div class="form-group" v-show="payNow == 1">
        <label for>Vuelto</label>
        <app-input-money
          :quantity.sync="reg.pay_change"
          :moneyCode="plan.money_code"
          :byKeyPress="false"
          :selCurrDis="true"
        ></app-input-money>
      </div>
    </div>

    <app-button-submit
      v-show="!sale_id"
      @click="letsToPay()"
      :disabled="sending"
      text="Registrar Operacion"
    >
      <i class="fas fa-money-bill"></i>
      Pagar
    </app-button-submit>
    <a
      v-show="sale_id"
      :href="backendUrl + '/print/store/sales-ticket/' + sale_id"
      class="btn btn-light"
    >
      <i class="fa fa-print"></i> Imprimir Ticket
    </a>
  </div>
</template>

<script>
import { DentalClinicService } from "../service";
import { round } from "lodash";
import ToothPiece from "../odontogram/ToothPiece";
import { mapState } from "vuex";

export default {
  components: {
    // SelectPaymentMethod,
    ToothPiece
  },
  data: () => ({
    reg: {},
    client_money: 0,
    payNow: 2,
    sale_id: undefined,
    backendUrl: process.env.VUE_APP_API_URL,
    productPays: {},
    sending: false
  }),
  props: {
    details: {},
    plan: {}
    // payMoney: true
  },
  computed: {
    ...mapState({
      products: (s) => s.config.dental.treatments.products_to_pay
    }),
    toPay() {
      return this.details.filter((x) => {
        return !x.paid_out;
      });
    },
    total() {
      let total = 0;
      this.details
        .filter((x) => x.toPay)
        .map((x) => {
          total += parseFloat(x.unit_price - x.amount_paid);
        });

      for (let it in this.productPays) {
        total += parseFloat(this.productPays[it]);
      }

      return this.payNow == 2 ? total - this.plan.payment : total;
    }
  },
  methods: {
    calcularTotal() {
      //
    },

    pf: (num) => {
      let val = round(num, 2);
      return val > 0 ? val : 0;
    },
    /**@public */
    show() {
      // this.getClientMoneyAccount();
      this.client_money = 1;
      this.reset();
    },

    reset() {
      this.reg = {};
      this.reg.payment_method_id = 1;
      // this.client_money = 0;
      this.payNow = 1;
      this.sale_id = undefined;
      this.sending = false;
    },
    // getClientMoneyAccount() {
    //   let accounts = this.$store.state.dental.client
    //     ? this.$store.state.dental.client.accounts
    //     : [];
    //   let moneyAccounts = accounts.filter(
    //     x => x.money_code == this.plan.money_code
    //   );
    //   this.client_money =
    //     moneyAccounts.length > 0 ? parseFloat(moneyAccounts[0].quantity) : 0;
    // },
    letsToPay() {
      this.sending = true;
      let listToPay = this.details
        .filter((x) => x.toPay)
        .map((x) => {
          return {
            product_id: x.product_id,
            treatment_plan_detail_id: x.id
          };
        });

      DentalClinicService.letsToPayTPD({
        plan_id: this.plan.id,
        list: listToPay,
        ...this.reg,
        // pay_change: 0,
        payNow: this.payNow,
        adds: this.productPays
      })
        .then(
          (res) => {
            this.$emit("paidOut");
            this.sale_id = this.payNow ? res : undefined;
          },
          (err) => {
            this.$store.dispatch("showError", err.response.data.message);
          }
        )
        .finally(() => {
          this.sending = false;
        });
    }
  }
};
</script>

<style></style>
