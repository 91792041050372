<template>
  <div>
    <h4>SIMULACION</h4>
    <table class="table">
      <tr v-for="(l, i) in listDues" :key="i">
        <td>{{ ordenCuota(l.due) }}</td>
        <td>
          <template v-if="l.due == 0">
            <app-datepicker v-model="l.expiration"></app-datepicker>
          </template>
          <template v-else>
            <span>{{ l.expiration | dateFormat }}</span>
          </template>
        </td>
        <td>
          <app-span-input
            :value="l.amount"
            :sm="true"
            @input="recalculate(i, $event)"
          >
            <app-span-money
              :moneyCode="1"
              :quantity="l.amount"
            ></app-span-money>
          </app-span-input>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { ordenCuota } from "./utils";
import { myMoment } from "../../utils/myMoment";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    config: {
      default: {
        amount: 0,
        initial_amount: 0,
        dues: 1,
        initial_dues: 1,
        begin_date: ""
      }
    }
  },

  data: () => ({
    listDues: []
  }),

  computed: {
    //
  },

  watch: {
    config() {
      this.listDues = [];

      let todayMoment = myMoment();
      for (let i = 0; i < parseFloat(this.config.initial_dues); i++) {
        this.listDues.push({
          due: 0,
          expiration: todayMoment.format("YYYY-MM-DD"),
          amount:
            parseFloat(this.config.initial_amount) /
            parseFloat(this.config.initial_dues)
        });
      }

      let dateDue = myMoment(this.config.begin_date, "YYYY-MM-DD");
      for (let i = 0; i < parseFloat(this.config.dues); i++) {
        this.listDues.push({
          due: i + 1,
          expiration: dateDue.format("YYYY-MM-DD"),
          amount:
            (parseFloat(this.config.amount) -
              parseFloat(this.config.initial_amount)) /
            parseFloat(this.config.dues)
        });
        dateDue.add(1, "month");
      }
    }
  },

  mounted() {
    //
  },

  methods: {
    getDues() {
      return this.listDues;
    },
    ordenCuota,
    recalculate(i, newAmount) {
      if (newAmount <= 0) {
        return;
      }

      newAmount = parseFloat(newAmount);
      let rest = this.listDues
        .filter((x, y) => y > i - 1)
        .map(x => parseFloat(x.amount))
        .reduce((a, b) => a + b, 0);

      let restAmount = rest - newAmount;
      let restDues = this.listDues.filter((x, y) => y > i).length;

      if (restDues == 0) {
        return;
      }

      this.listDues.map((x, y) => {
        if (y == i) {
          x.amount = newAmount;
        }
        if (y > i && restDues > 0) {
          x.amount = restAmount / restDues;
        }
        return x;
      });
    }
  }
};
</script>

<style></style>
