<template>
  <div class="row">
    <div class="col">
      <Simulate
        ref="elSimulate"
        v-show="letsSimulate"
        :config="configSimulate"
      ></Simulate>
      <table class="table" v-show="!letsSimulate && !to_bonus">
        <tbody>
          <tr v-for="l in noPaid" :key="l.id">
            <td>
              <app-checkbox v-model="l.toFinancing" @input="amount = total">{{
                l.product_name
              }}</app-checkbox>
            </td>
            <td :class="{ 'text-success': l.maked }">
              <span>{{ l.maked ? "Realizado" : "Pendiente" }}</span>
              <br />
              <ToothPiece
                v-for="l1 in l.th_list"
                :key="l1"
                :piece="l1"
              ></ToothPiece>
            </td>
            <td class="text-right">
              <app-span-money
                :quantity="l.unit_price"
                :moneyCode="plan.money_code"
              ></app-span-money>
            </td>
          </tr>
          <tr>
            <th>TOTAL</th>
            <th></th>
            <th class="text-right">
              <app-span-money
                :quantity="total"
                :moneyCode="plan.money_code"
              ></app-span-money>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col">
      <form @submit.prevent="save()">
        <div class="form-group">
          <app-checkbox
            v-model="to_bonus"
            placeholder="Monto libre"
          ></app-checkbox>
          <app-small-form-errors
            :errors="errors.to_bonus"
          ></app-small-form-errors>
        </div>
        <div class="form-group">
          <label for>Monto Total</label>
          <app-input-money
            :selCurrDis="true"
            :disabled="!to_bonus"
            :moneyCode="plan.money_code"
            :quantity.sync="amount"
          ></app-input-money>
          <app-small-form-errors
            :errors="errors.amount"
          ></app-small-form-errors>
        </div>
        <div class="form-group">
          <label for>Inicial</label>
          <app-input-money
            :selCurrDis="true"
            :moneyCode="plan.money_code"
            :quantity.sync="initial_amount"
          ></app-input-money>
          <app-small-form-errors
            :errors="errors.initial_amount"
          ></app-small-form-errors>
        </div>

        <div class="form-group">
          <label for>Monto Sin Inicial</label>
          <app-input-money
            :selCurrDis="true"
            :moneyCode="plan.money_code"
            :quantity.sync="amountWithoutInitial"
            :disabled="true"
          ></app-input-money>
        </div>

        <div class="form-group">
          <label for>Fecha de primera cuota</label>
          <app-datepicker v-model="begin_date"></app-datepicker>
          <app-small-form-errors
            :errors="errors.begin_date"
          ></app-small-form-errors>
        </div>
        <div class="form-group">
          <label for>Cantidad de Cuotas del Inicial</label>
          <app-input-number v-model="initial_dues"></app-input-number>
          <app-small-form-errors
            :errors="errors.initial_dues"
          ></app-small-form-errors>
        </div>
        <div class="form-group">
          <label for>Cantidad de Cuotas</label>
          <app-input-number v-model="dues"></app-input-number>
          <app-small-form-errors :errors="errors.dues"></app-small-form-errors>
        </div>

        <app-button-submit>Financiar</app-button-submit>
        <button
          class="btn btn-primary ml-1"
          type="button"
          @click="letsSimulate = false"
        >
          Ver Tratamientos
        </button>
        <button
          type="button"
          class="ml-1 btn btn-success"
          @click="
            letsSimulate = true;
            configSimulate = {
              amount: amount,
              initial_amount: initial_amount,
              dues: dues,
              initial_dues: initial_dues,
              begin_date: begin_date
            };
          "
        >
          Simular
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { DentalClinicService } from "../service";
import ToothPiece from "../odontogram/ToothPiece";
import validate from "validate.js";
import Simulate from "./Simulate.vue";
import { myMoment } from "../../utils/myMoment";

const formRules = {
  amount: {
    presence: { message: "Requerido" },
    numericality: {
      greaterThan: 0,
      notGreaterThan: "Debe ser mayor a o"
    }
  },
  dues: {
    presence: { message: "Requerido" },
    numericality: {
      onlyInteger: true,
      notInteger: "Debe ser entero",
      greaterThan: 0,
      notGreaterThan: "Debe ser mayor a o"
    }
  },
  begin_date: { presence: { message: "Requerido" } },
  initial_amount: { presence: { message: "Requerido" } },
  initial_dues: { presence: { message: "Requerido" } }
  // to_bonus: { presence: { message: "Requerido" } }
};

export default {
  components: { ToothPiece, Simulate },
  props: {
    treatment_plan_id: {},
    plan: {},
    details: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    amount: undefined,
    dues: undefined,
    begin_date: undefined,
    initial_amount: undefined,
    initial_dues: undefined,
    to_bonus: undefined,
    letsSimulate: false,
    configSimulate: {}
  }),
  computed: {
    amountWithoutInitial() {
      return parseFloat(this.amount) - parseFloat(this.initial_amount);
    },
    errors() {
      let err = validate(this.$data, formRules, { fullMessages: false });
      return err ? err : false;
    },
    noPaid() {
      return this.details.filter((x) => {
        return !x.paid_out && !x.financied;
      });
    },
    total() {
      let total = 0;
      this.details
        .filter((x) => x.toFinancing)
        .map((x) => {
          if (x.toFinancing) {
            total += parseFloat(x.unit_price);
          }
        });
      return total;
    }
  },

  methods: {
    reset() {
      // this.load();
      this.initial_amount = 0;
      this.initial_dues = 0;
      this.amount = 0;
      this.dues = 0;
      this.begin_date = myMoment().format("YYYY-MM-DD");
    },
    save() {
      DentalClinicService.saveFinancing({
        ...this.$data,
        details: this.details.filter((x) => x.toFinancing).map((x) => x.id),
        treatment_plan_id: this.treatment_plan_id,
        template: this.letsSimulate
          ? this.$refs.elSimulate.getDues()
          : undefined
      }).then((res) => {
        this.$emit("submitted", res);
      });
    }
    // load() {
    //   this.amount = this.plan.sum_budget;
    // }
  }
};
</script>

<style></style>
