<template>
  <div class="dropdown dropleft" :key="'dm' + l.id">
    <div
      class="dropdown-menu"
      ref="elMenu"
      aria-labelledby="dropdownMenuButton"
    >
      <a
        href="#"
        class="dropdown-item"
        :class="{ disabled: listToEvol.length == 0 }"
        @click.prevent="$emit('evolution')"
        v-show="config.edit"
      >
        Evolucionar
        <span v-show="listToEvol.length > 0">({{ listToEvol.length }})</span>
      </a>

      <a
        href="#"
        class="dropdown-item"
        :class="{ disabled: listToEvol.length == 0 }"
        @click.prevent="$emit('quote')"
      >
        Citar
        <span v-show="listToEvol.length > 0">({{ listToEvol.length }})</span>
      </a>
      <a
        href="#"
        class="dropdown-item"
        :class="{ disabled: listToEvol.length == 0 }"
        @click.prevent="$emit('deleteSelected')"
        v-show="config.edit"
      >
        Eliminar
        <span v-show="listToEvol.length > 0">({{ listToEvol.length }})</span>
      </a>
      <!-- <a
        href="#"
        class="dropdown-item"
        :class="{ disabled: listToEvol.length == 0 }"
        @click.prevent="$emit('quote')"
      >
        Agregar a cita existente
        <span v-show="listToEvol.length > 0">({{ listToEvol.length }})</span>
      </a> -->
      <div class="dropdown-divider"></div>
      <a
        href="#"
        class="dropdown-item"
        v-show="l.employee_id && config.edit"
        @click.prevent="$emit('motrarOcultar')"
      >
        {{ l._show_details ? "Ocultar" : "Mostrar" }} Detalles
      </a>
      <a
        href="#"
        class="dropdown-item"
        v-show="!l.maked && config.edit"
        @click.prevent="$emit('markToMake')"
      >
        {{ l.to_make ? "Desmarcar" : "Marcar" }} para realizar
      </a>
      <a
        href="#"
        v-show="!l.maked && config.edit"
        class="dropdown-item"
        @click.prevent="$emit('toDelete')"
      >
        Eliminar
      </a>
      <a
        href="#"
        v-show="l.maked && config.edit"
        class="dropdown-item"
        @click.prevent="$emit('inEvolution')"
      >
        Desevolucionar
      </a>
      <a
        href="#"
        class="dropdown-item"
        @click.prevent="$emit('prePaid')"
        v-show="config.edit && !(l.amount_paid == l.unit_price) && !l.financied"
      >
        Asignar Abonos
      </a>
      <a
        href="#"
        class="dropdown-item"
        @click.prevent="$emit('noPrePaid')"
        v-show="config.edit && !(l.maked && l.paid_out) && !l.financied"
      >
        Desvincular Abonos
      </a>
      <a
        href="#"
        class="dropdown-item"
        :class="{ disabled: l.maked }"
        @click.prevent="$emit('reqLaboratory')"
        v-show="config.edit"
      >
        Solicitar a laboratorio
      </a>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    l: {},
    listToEvol: {}
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapState({
      config: s => s.config.service.treatments
    })
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    showMenu() {
      this.$refs.elMenu.classList.add("show");
    },
    hideMenu() {
      this.$refs.elMenu.classList.remove("show");
    }
  }
};
</script>

<style></style>
