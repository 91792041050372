<template>
  <span
    :class="{ 'text-success': paid_out, 'text-warning': !paid_out }"
    style="font-size: 1.5em;"
  >
    <i class="fa fa-table"></i>
  </span>
</template>

<script>
export default {
  props: {
    paid_out: {}
  }
};
</script>

<style>
</style>
